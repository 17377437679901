import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';

import './Layout.css';





export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <Container id="page-wrap">
          <div className="sidebar sidebar-left">
            <Container>
              <Row className="justify-content-center">
               
              </Row>
              <Row className="justify-content-center">
               
              </Row>
            </Container>
          </div>
          <div id="mainContainer">
            {this.props.children}
          </div>
        </Container>
       
      </div>
    );
  }
}
