import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
const en = require('./assets/languages/en.json')
const pt = require('./assets/languages/pt.json')

const resources = {
    en: {
        translation: en
    },
    pt: {
        translation: pt
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;