import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Shared/Layout';
import { Home } from './components/Home/Home';
import { Spontaneous } from './components/Spontaneous/Spontaneous';
import { Curricular } from './components/Curricular/Curricular';
import { SuccessAidRequest } from './components/SuccessAidRequest/SuccessAidRequest';
import { Success } from './components/Success/Success';
import { withTranslation } from 'react-i18next';
import { loadReCaptcha } from 'react-recaptcha-v3'

import './custom.css'
import { Fail } from './components/Fail/Success';
import { unregister } from './registerServiceWorker';

const MySpontaneous = withTranslation()(Spontaneous)
const MySuccessAidRequest = withTranslation()(SuccessAidRequest)
const MyHome = withTranslation()(Home)
const MyCurricular = withTranslation()(Curricular)
const MySuccess = withTranslation()(Success)
const MyFail = withTranslation()(Fail)
export default class App extends Component {
  static displayName = App.name;

  componentDidMount() {
      loadReCaptcha("6LfDqSIbAAAAAJlBlcDYPGOUwbocyUVRO-hVnGI7");//6LeQyusZAAAAAMFhuKc4ogmDNpdGdnK5Mqs2YMLx

  }

  render() {
      return (
         <div> <CacheBuster>
            
          </CacheBuster>
        <Layout>

            <Route exact path='/' component={MySpontaneous} />
            <Route path='/success' component={MySuccess} />
            <Route path='/fail' component={MyFail} />
            <Route path='/successAidRequest' component={MySuccessAidRequest} />
            <Route path='/feedback/:id' component={MyCurricular} />
      </Layout></div>
    );
  }
}
// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

class CacheBuster extends React.Component {
    
    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = window.name == "" ? global.appVersion : window.name;
                unregister();
                const shouldForceRefresh = latestVersion != currentVersion;
                if (shouldForceRefresh) {

                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    console.log('Clearing cache and hard reloading...')
                    if (caches) {
                        // Service worker cache should be cleared with caches.delete()
                        caches.keys().then(function (names) {
                            for (let name of names) caches.delete(name);
                        });
                    }
                    unregister();
                    window.name = latestVersion
                    window.location.reload(true);

                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                   
                }
            });
        return null;
    }
    render() {
      return( <div></div>)
    }
}

