import React, { Component } from 'react';
import './SuccessAidRequest.css';
import { Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export class SuccessAidRequest extends Component {

    render() {
        const { t } = this.props;
        return (
            <Container className="successContainer">
                <Row className="justify-content-center">
                    <h1>Sucesso</h1>
                </Row>
                <Row className="justify-content-center sucessLines">
                    <FontAwesomeIcon icon={faCheckCircle} size="10x" className="iconColorBlue" />
                </Row>
                <Row className="justify-content-center sucessLines">
                    <span>Pedido realizado com sucesso! <a href="/"> Carregue aqui</a> para submeter outro pedido.</span>    
                </Row>
                <Row className="justify-content-center sucessLines">
                    <span>Será em breve contatado por uma entidade da sua zona de residência, para dar resposta ao seu pedido de apoio.</span>
                    <span>Se conhecer outras pessoas em situação de carência alimentar, pode partilhar este formulário. </span>
                </Row>
                <Row className="justify-content-center sucessLines">
                    <b>Ajudar não pode parar!</b>
                </Row>
                <Row className="justify-content-center sucessLines">
                    <div className="logo"></div>
                </Row>

            </Container>
        );
    }
}