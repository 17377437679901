import React, { Component } from 'react';
import './fail.css';
import { Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

export class Fail extends Component {
    //static displayName = Success.name;

    render() {
        const { t } = this.props;
        return (
            <Container className="successContainer">
                <Row className="justify-content-center">
                    <h1>O código do pedido de ajuda inserido não é valido</h1>
                </Row>
                <Row className="justify-content-center sucessLines">
                    <FontAwesomeIcon icon={faExclamationCircle} size="10x" className="iconColor" />
                </Row>
                <Row className="justify-content-center sucessLines">
                    <Link className="linkColor" to="/">  <span>Por favor tente novamente</span></Link>
                  
                </Row>
            </Container>
        );
    }
}