/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import './Spontaneous.css';
import TextField from '@material-ui/core/TextField';
import { Container, Row, Col } from 'reactstrap';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { faLongArrowAltRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { ReCaptcha } from 'react-recaptcha-v3'
import Checkbox from '@material-ui/core/Checkbox';
import { pt } from "date-fns/locale";
import { enUS } from "date-fns/locale";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Loader from "react-loader-spinner";
export class Spontaneous extends Component {
    static displayName = "Pedido de ajuda";

    constructor(props) {
        super(props);
        this.state = {
            emailRegex: /^\S+@\S+\.\S+$/,
            recaptcha: null,
            spinner:true,
            captchaValue: (''),
            open: false,
            captcha: false,
            language: (''),
            masterData: [],
            name: (''),nameError: false,nameMessage: (''),
            birthday: null,
            adress: (''),adressError: false,adressMessage: (''),
            zoneIndicator: 351,
            phoneNumber: (''),phoneNumberError: false,phoneNumberMessage: (''),
            email: (''),emailError:false,emailMessage:false,
            zipCode: (''),
            nationality: (''),
            livesAlone: (''),livesAloneError: false,livesAloneMessage: (''),
            Niss: ('Sim'),
            document: (''),
            CC: (''),CCError:false,CCMessage:(''),
            NIF: (''),NIFError:false,NIFMessage:(''),
            passaporte: (''),
            visa: (''),
            nissValue: (''),nissError: false,nissMessage: (''),
            under12: (''), under12Error: false,under12Message: (''),
            thirteenTo17:(''),thirteenTo17Error:false,under12ErrorMessage: (''),
            eighteenToThirty: (''),eighteenToThirtyError: false,
            thirtyOneToSixtyFive: (''),thirtyOneToSixtyFiveError: false,
            overSixtyFive: (''),overSixtyFiveError: false,
            aggregate: (''),
            degree: (''),
            job: (''),
            support: false,
            supportLocation: (''),
            fridge: false,
            frezer: false,
            canCook: false,
            canGetProducts: false,
            motivo: (''),
            sugestao: (''),sugestaoError: false,sugestaoMessage: (''),
            other: (''),
            distrito:(''),aggregateError:false,aggregateMessage:(''),
            error:false,
            zipCodeError:false,
            zipCodeValid:false,
            concelho:(''),
            freguesia:(''),
            freguesiaFinal:(''),
            autorizacao: (''),
            validAgregate: true,
        };
        this.handleBirthday = this.handleBirthday.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNiss = this.handleNiss.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.handleZipCode = this.handleZipCode.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.addEmptyValue = this.addEmptyValue.bind(this);
        this.handleChangeDoc = this.handleChangeDoc.bind(this);
        this.checkValidityCC = this.checkValidityCC.bind(this);
        this.checkStrings = this.checkStrings.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.checkValidityNISS = this.checkValidityNISS.bind(this);
        this.checkPtPhone = this.checkPtPhone.bind(this);
        this.checkOtherPhone = this.checkOtherPhone.bind(this);
        this.checkNif = this.checkNif.bind(this);
        this.checkValidityNumberFields = this.checkValidityNumberFields.bind(this);
        this.handleKeyDownPtPhone = this.handleKeyDownPtPhone.bind(this);
        this.handleKeyDownOtherPhone = this.handleKeyDownOtherPhone.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyDownCC = this.handleKeyDownCC.bind(this);
        this.handleKeyDownNIF = this.handleKeyDownNIF.bind(this);
        this.validateAggregate = this.validateAggregate.bind(this);
        this.checkValidityAggregateField = this.checkValidityAggregateField.bind(this);
    }

    closeDialog(event) {
        this.setState({ open: false })
    }
    openDialog(event) {
        this.setState({ open: true })
    }

    handleChangeDoc(event) {
        this.setState({  document: (''),
        CC: undefined,CCError:false,CCMessage:(''),
        NIF: undefined,NIFError:false,NIFMessage:(''),
        visa: (''),other:(''),
        passaporte: (''),
        nissValue: (''),nissError: false,nissMessage: ('')});
        this.handleChange(event);
    }
    handleChange(event) {
        if (event.target) {
            const name = event.target.name;
            this.setState({ [name]: event.target.value });
        }
    }

    handleChangeFileName(newFileName) {
        this.setState({ vName: newFileName })

    }

    handleZipCode(event) {
        if(event.target.value.split("-").length === 2 && event.target.value.split("-")[0].length === 4 && event.target.value.split("-")[1].length === 3){
            fetch('externalinfo/getLocationById?id=' + this.state.zipCode, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then((result) => {
                if (result.status === 200) {
                    result.json().then((data) => {
                        this.setState({ error: false, zipCodeValid: true, zipCodeError: false, concelho: data.concelho_map , freguesia:data.freguesia_map, distrito:data.distrito_map , freguesiaFinal:data.freguesiaFinal});
                        console.log(data)
                    });
                } else {
                    console.log("valid true")
                    this.setState({ error: true, zipCodeValid: true, zipCodeError: true, zipCodeMessage: "Erro a procurar código postal. P.f verifique o valor e o formato inseridos.", concelho: "", freguesia: "", distrito: "", freguesiaFinal: "" });
                }
            }
            );
        } else {
            this.setState({ zipCodeValid: false, zipCodeError: true, zipCodeMessage: "Erro a procurar código postal. P.f verifique o valor e o formato inseridos.", concelho: "", freguesia: "", distrito: "", freguesiaFinal: "" });       
        }
    }

    checkValidityCC(event) {
        if(event.target.value.toString().length !== 8){
            this.setState({ [event.target.name+"Error"]:true , [event.target.name+"Message"]:"O número do cartão de cidadão deve ter 8 dígitos." });
        } else {
            this.setState({ [event.target.name+"Error"]:false , [event.target.name+"Message"]:"" });

        }
    }

    checkNif(event) {
        if(event.target.value.toString().length !== 9){
            this.setState({ [event.target.name+"Error"]:true , [event.target.name+"Message"]:"O NIF deve ter 9 dígitos." });
        } else {
            this.setState({ [event.target.name+"Error"]:false , [event.target.name+"Message"]:"" });

        }
    }

    checkStrings(event) {
        if(/\d/.test(event.target.value)){
            this.setState({ [event.target.name+"Error"]:true , [event.target.name+"Message"]:"Não deve conter números!" });
        } else {
            this.setState({ [event.target.name+"Error"]:false , [event.target.name+"Message"]:"" });

        }
    }
    checkEmail(event) {
        if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value)){
            this.setState({ ["emailError"]:true , ["emailMessage"]:"Formato de email inválido!" });
        } else {
            this.setState({ ["emailError"]:false , ["emailMessage"]:"" });

        }
    }

    checkValidityNISS(event) {
        
        if(event.target.value <10000000000 || event.target.value>99999999999){
            this.setState({ ["nissError"]:true , ["nissMessage"]:"O NISS deve ter 11 dígitos." });
        } else {
            this.setState({ ["nissError"]:false , ["nissMessage"]:"" });

        }
    }

    checkPtPhone(event) {
        
        if(event.target.value.toString().length != 9){
            this.setState({ [event.target.name+"Error"]:true , [event.target.name+"Message"]:"O número de telefone deve ter 9 dígitos" } );
        } else {
            this.setState({ [event.target.name+"Error"]:false , [event.target.name+"Message"]:"" });

        }
    }

    checkOtherPhone(event) {
        
        if(event.target.value.toString().length <6  ||event.target.value.toString().length >20){
            this.setState({ [event.target.name+"Error"]:true , [event.target.name+"Message"]:"O número de telefone deve ter entre 6 e 20 dígitos" } );
        } else {
            this.setState({ [event.target.name+"Error"]:false , [event.target.name+"Message"]:"" });

        }
    }

    checkValidityAggregateField(event) {

        if (event.target.value > 99 || event.target.value < 0) {
            this.setState({ [event.target.name + "Error"]: true, [event.target.name + "Message"]: "O número de pessoas deve ter no máximo 2 dígitos" });
        } else if (event.target.value === 0) {
            this.setState({ [event.target.name + "Error"]: true, [event.target.name + "Message"]: "O valor indicado não pode ser zero" });
        } else {
            this.setState({ [event.target.name + "Error"]: false, [event.target.name + "Message"]: "" });
        }

    }

    checkValidityNumberFields(event) {
        if(event.target.value >99 || event.target.value<0){
            this.setState({ [event.target.name+"Error"]:true , [event.target.name+"Message"]:"O número de pessoas deve ter no máximo 2 dígitos" });
        } else {
            this.setState({ [event.target.name+"Error"]:false , [event.target.name+"Message"]:"" });
        }
        // event.target.form.reportValidity()
     }
    handleBirthday(event) {
        if (event)
            this.setState({ birthday: event });
        else this.setState({ birthday: "" });
    }

    handleChangeCheckbox(event) {
        if (event.target) {
            const name = event.target.name;
            this.setState({ [name]: event.target.checked });
        }
    }
    handleNiss(event) {
        this.setState({ document: (''),
        CC: undefined,CCError:false,CCMessage:(''),
        NIF: undefined,
        passaporte: (''),other:(''),
        visa: ('') });
        this.handleChange(event);
        
    }
    
    handleKeyDownPtPhone(event) {
       if( event.target.value.toString().length <= 9){
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            
            this.handleChange(event);
         }
       }
    }
    handleKeyDownOtherPhone(event) {
       if( event.target.value.toString().length <= 20){
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            
            this.handleChange(event);
         }
       }
    }
    handleKeyDown(event) {
        if( event.target.value.toString().length <= 11){
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                
                this.handleChange(event);
             }
        }
     }
     handleKeyDownCC(event) {
        if( event.target.value.toString().length <= 8){
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                
                this.handleChange(event);
             }
        }
     }
     handleKeyDownNIF(event) {
        if( event.target.value.toString().length <= 9){
            const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            
            this.handleChange(event);
         }
        }
    }

    validateAggregate() {

        try {

            var sumAggregate = parseInt(this.state.under12) +
                parseInt(this.state.thirteenTo17) +
                parseInt(this.state.eighteenToThirty) +
                parseInt(this.state.thirtyOneToSixtyFive) +
                parseInt(this.state.overSixtyFive);

            if (sumAggregate === parseInt(this.state.aggregate)) {
                this.setState({ validAgregate: true });
                return true;
            }

            this.setState({ validAgregate: false });
            return false;

        } catch {
            this.setState({ validAgregate: false });
            return false;
        }

    }

    componentWillMount() {

        fetch('externalinfo/getmasterdata', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((result) => {
            if (result.status === 200) {
                result.json().then((data) => {
                    
                    this.setState({ masterData: data, spinner:false })
                });
            } else {
                console.log(result)
            }
        }
        );
    }

    handleSubmit(event) {

        var validAggregate = this.validateAggregate();
         if (validAggregate && this.state.zipCodeValid && this.state.birthday - Date.now() < 0 && !this.state.sugestaoError && !this.state.phoneNumberError && !this.state.emailError && !this.state.under12Error && !this.state.nameError && !this.state.CCError && !this.state.nissError && !this.state.thirteenTo17Error && !this.state.adressError && !this.state.zipCodeError && !this.state.livesAloneError && !this.state.aggregateError && !this.state.eighteenToThirtyError && !this.state.thirtyOneToSixtyFiveError && !this.state.overSixtyFiveError ) {
            fetch('aid/createform', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state)
            }).then((result) => {
                if (result.status === 200) {
                    this.props.history.push('/successAidRequest')
                } else {
                    console.log(result)
                    this.recaptcha.execute();

                    this.setState({ open: true });
                }
            }
            )
            event.preventDefault();
        }
        event.preventDefault();
    }

    verifyCallback = (recaptchaToken) => {
        console.log(recaptchaToken)
        if (recaptchaToken)
            this.setState({ captcha: true, captchaValue: recaptchaToken });
    }

    addEmptyValue(listName) {
        let saveState = this.state[listName];

        let newListItem;

        switch (listName) {
            case "experienceList":
                newListItem = { company: '', role: '', startDate: null, endDate: null };
                break;
            case "educationList":
                newListItem = { academicDegree: (''), course: (''), institution: (''), average: (''), dateOfConclusion: null };
                break;
            default:
                newListItem = []
        }

        saveState.push(newListItem)

        this.setState({ [listName]: saveState });
    }

    updateValue(item, label, index, listName) {
        let saveState = this.state[listName];
        saveState[index][label] = item;

        this.setState({ [listName]: saveState });
    }

    removeValue(index, listName) {
        let saveState = this.state[listName];
        saveState.splice(index, 1);
        this.setState({ [listName]: saveState });
    }

    render() {
        const { t, i18n } = this.props;
        this.state.language = i18n.language;

        return (<div className="containerParent">
            {this.state.spinner && 
                <div className="child">
                    <Loader
                        type="ThreeDots"
                        color="#000000"
                        height={200}
                        width={200}
                        visible={this.state.spinner}
                         //3 secs
                    /> 
                </div>
            }

            { !this.state.spinner &&
            <Container>
                
                <Row className="introImage justify-content-center">
               
                </Row>

                <Row className="rowSpace experienceTooltip">
                    <p>A Rede de Emergência Alimentar é uma resposta para situações de dificuldade vividas por famílias residentes em Portugal. Foi criada pela ENTRAJUDA, em articulação com os Bancos Alimentares, e está assente nas Instituições de Solidariedade Social, nas Câmaras Municipais, nas Juntas de Freguesia e noutras entidades que prestam apoio à comunidade. Visa encaminhar os pedidos de apoio alimentar para a resposta mais próxima da residência.</p>
                    <p> Se precisa de apoio alimentar, por favor preencha este formulário.</p>
                </Row>

                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Row>
                        <h4 className="sectionHeader">Pedido de Apoio</h4>
                    </Row>
                    <Row className="rowSpace">
                        <Col xl="4" lg="12" md="12">
                            <TextField value={this.state.name} onBlur={this.checkStrings} error={this.state.nameError} helperText={this.state.nameMessage} name="name" label="Nome" onChange={this.handleChange} fullWidth required inputProps={{ maxLength: 100 }} />
                        </Col>
                        <Col xl="4" lg="6" md="6">
                            <MuiPickersUtilsProvider locale={i18n.language === 'pt' ? pt : enUS} utils={DateFnsUtils}>
                                <KeyboardDatePicker name="birthday" label="Data de Nascimento" cancelLabel={i18n.language === 'pt' ? "CANCELAR" : "CANCEL"} format="dd/MM/yyyy" value={this.state.birthday}
                                    onChange={this.handleBirthday} KeyboardButtonProps={{ 'aria-label': 'change date' }} fullWidth required disableFuture />
                            </MuiPickersUtilsProvider>
                        </Col>
                        <Col xl="4" lg="6" md="12">
                            <TextField value={this.state.adress}  name="adress" label="Morada" onChange={this.handleChange} fullWidth required inputProps={{ maxLength: 100 }} />
                        </Col>
                    </Row>

                    <Row className="rowSpace experienceTooltip">
                        <Col xl="2" lg="4" md="4">
                            <FormControl fullWidth required>
                                    <InputLabel id="genre-label">Indicativo</InputLabel>
                                    <Select native labelId="genre-label" name="zoneIndicator" value={this.state.zoneIndicator} onChange={this.handleChange} displayEmpty required  >
                                        {
                                            this.state.masterData.map(function (item, i) {

                                                if (item.key === "INDICATIVO")
                                                    return <option value={item.value.split("-")[0]}>{item.value}</option>
                                            })}
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col xl="2" lg="5" md="9">
                            {this.state.zoneIndicator === 351 && 
                                <TextField value={this.state.phoneNumber} onChange={this.handleKeyDownPtPhone} onBlur={this.checkPtPhone}  error={this.state.phoneNumberError} helperText={this.state.phoneNumberMessage}   name="phoneNumber" label="Número de telefone"  fullWidth required inputProps={{ maxLength: 9, minLength: 9 }} />
                            }

                            {this.state.zoneIndicator !== 351 && 
                                <TextField value={this.state.phoneNumber} onChange={this.handleKeyDownOtherPhone} onBlur={this.checkOtherPhone} error={this.state.phoneNumberError} helperText={this.state.phoneNumberMessage} name="phoneNumber" label="Número de telefone" fullWidth required inputProps={{ maxLength:20, minLength: 6}} />
                            }
                        </Col>
                        <Col xl="4" lg="6" md="12">
                        <TextField value={this.state.email}  name="email" onBlur={this.checkEmail} error={this.state.emailError} helperText={this.state.emailMessage} label="Email" onChange={this.handleChange} fullWidth required/>

                        </Col>
                        <Col xl="4" lg="6" md="12">
                            <TextField value={this.state.livesAlone} error={this.state.livesAloneError} helperText={this.state.livesAloneMessage} onBlur={this.checkStrings} name="livesAlone" label="Localidade" onChange={this.handleChange} fullWidth required inputProps={{ maxLength: 100 }} />


                        </Col>
                    </Row>

                    <Row className="rowSpace experienceTooltip">
                        <Col xl="4" lg="3" md="12">
                            <FormControl fullWidth required>
                                <InputLabel id="genre-label">Nacionalidade</InputLabel>
                                <Select native labelId="genre-label" name="nationality" value={this.state.nationality} onChange={this.handleChange} displayEmpty required inputProps={{ max: 2 }} >
                                    <option aria-label="None" value="" />
                                    {
                                        this.state.masterData.map(function (item, i) {

                                            if (item.key === "NACIONALIDADE")
                                                return <option value={item.value}>{item.value}</option>
                                        })}
                                </Select>
                            </FormControl>
                        </Col>
                         <Col xl="4" lg="3" md="12">
                                <TextField
                                    name="zipCode" fullWidth required
                                    value={this.state.zipCode}
                                    error={this.state.zipCodeError}
                                    label="Código Postal (formato XXXX-XXX)"
                                    onChange={this.handleChange}
                                    onBlur={this.handleZipCode}
                                    inputProps={{ maxLength: 8, minLength: 8, pattern: "[0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]" }} />
                        </Col>
                            {(this.state.distrito !== "" && this.state.freguesiaFinal !== "" && this.state.concelho !== "") && <Col xl="4" lg="3" md="12"><div className="blackColor">{this.state.freguesiaFinal} - {this.state.concelho}, distrito {this.state.distrito}<div className="MuiFormHelperText-root MuiFormHelperText-filled Mui-required">Por favor, verifique se a Freguesia, o Concelho e o Distrito correspondem à sua morada!</div></div></Col>}

                            {this.state.zipCodeError && <Col xl="4" lg="3" md="12"><div className="blackColor">{this.state.zipCodeMessage}</div></Col>}
                    </Row>

                    <Row className="rowSpace experienceTooltip">
                        <Col xl="2" lg="3" md="12">
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Tem NISS?</FormLabel>
                                <RadioGroup aria-label="gender" name="Niss" value={this.state.Niss} onChange={this.handleNiss}>
                                    <FormControlLabel value="Sim" control={<Radio />} label={<div className="blackColor">Sim</div>} />
                                    <FormControlLabel value="Não" control={<Radio />} label={<div className="blackColor">Não</div>} /> </RadioGroup>
                            </FormControl>
                        </Col>
                        <Col xl="3" lg="9" md="12">
                            {
                                this.state.Niss === "Não" &&
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Documento</FormLabel>
                                    <RadioGroup aria-label="gender" required name="document" value={this.state.document} onChange={this.handleChangeDoc}>

                                        <FormControlLabel value="CC" control={<Radio />} label={<div className="blackColor">Cartão de Cidadão</div>} />
                                        <FormControlLabel value="NIF" control={<Radio />} label={<div className="blackColor">NIF</div>} />
                                        <FormControlLabel value="Passaporte" control={<Radio />} label={<div className="blackColor">Passaporte</div>} />
                                        <FormControlLabel value="Autorização" control={<Radio />} label={<div className="blackColor">Autorização de residência</div>} />
                                        <FormControlLabel value="Outro" control={<Radio />} label={<div className="blackColor">Outro</div>} />

                                    </RadioGroup>
                                </FormControl>
                            }

                            {this.state.Niss === "Sim" &&
                                <TextField value={this.state.nissValue} onChange={this.handleKeyDown} onBlur={this.checkValidityNISS} error={this.state.nissError}  helperText={this.state.nissMessage}  name="nissValue" label="Número identificação da segurança social"  inputProps={{style: {fontSize: 17, position:"relative" , top:"-20px"}  }} InputLabelProps={{style: {fontSize: 17, position:"absolute" , top:"-20%"} }} required fullWidth inputProps={{ minLength: 11, maxLength: 11 }} />
                            }
                        </Col>

                        <Col xl="7" lg="9" md="12">
                            {this.state.document === "CC" &&
                                <TextField value={this.state.CC} onChange={this.handleKeyDownCC} name="CC" error={this.state.CCError} helperText={this.state.CCMessage} onBlur={this.checkValidityCC}  required label="Cartão do cidadão"  fullWidth inputProps={{ min: 1000000, max: 99999999 }} />
                            }
                            {this.state.document === "NIF" &&
                                <TextField value={this.state.NIF} onChange={this.handleKeyDownNIF} name="NIF" onBlur={this.checkNif} error={this.state.NIFError} helperText={this.state.NIFMessage}   required label="Número de identificação fiscal"  fullWidth inputProps={{ min: 100000000, max: 999999999 }} />
                            }
                            {this.state.document === "Passaporte" &&
                                <TextField value={this.state.passaporte} onBlur={this.checkValidity} required name="passaporte" label="Passaporte" onChange={this.handleChange} fullWidth />
                            }
                            {this.state.document === "Outro" &&
                                <TextField value={this.state.other} onBlur={this.checkValidity} required name="other" label="Outro" onChange={this.handleChange} fullWidth />
                            }
                            {this.state.document === "Autorização" &&
                                <TextField value={this.state.visa} onBlur={this.checkValidity} required name="visa" label="Autorização de residência" onChange={this.handleChange} fullWidth />
                            }
                        </Col>
                    </Row>

                    <Row className="rowSpace experienceTooltip">
                        <Col xl="2" lg="3" md="12"><div className="marginTop">
                                <TextField value={this.state.aggregate} errorvar="aggregateError" error={this.state.aggregateError} helperText={this.state.aggregateMessage} onBlur={this.checkValidityAggregateField} type="number" name="aggregate" label={<span className="paddingClass">Composição do agregado familiar (Incluindo-o a si)</span>} inputProps={{style: {fontSize: 17, position:"relative" , top:"-20px"}  }} InputLabelProps={{style: {fontSize: 17, position:"absolute" , top:"-20%"} }} required onChange={this.handleChange} fullWidth inputProps={{ min: 0, max: 99, maxLength:2 }} />
                            </div>  </Col>
                        <Col xl="2" lg="3" md="12"><div className="marginTop">
                            <TextField value={this.state.under12} className="marginTop" errorvar="under12Error" error={this.state.under12Error} helperText={this.state.under12Message} onBlur={this.checkValidityNumberFields} type="number" name="under12" label="Crianças com menos de 12 anos" required inputProps={{style: {fontSize: 17 , marginTop:"100px"}}} InputLabelProps={{style: {fontSize: 17}}} InputLabelProps={{style: {fontSize: 17, position:"absolute" , top:"-20%"} }} onChange={this.handleChange} fullWidth inputProps={{ min: 0, max: 99 }} />
                            </div>  </Col>
                        <Col xl="2" lg="3" md="12"><div className="marginTop">
                            <TextField value={this.state.thirteenTo17} errorvar="thirteenTo17Error" error={this.state.thirteenTo17Error} helperText={this.state.thirteenTo17Message} onBlur={this.checkValidityNumberFields} type="number" name="thirteenTo17" label="Crianças 13-17 anos" required inputProps={{style: {fontSize: 17}}}  InputLabelProps={{style: {fontSize: 17, position:"absolute" , top:"-20%"} }} onChange={this.handleChange} fullWidth inputProps={{ min: 0, max: 99 }} />
                            </div>  </Col>
                        <Col xl="2" lg="3" md="12"><div className="marginTop">
                            <TextField value={this.state.eighteenToThirty} errorvar="eighteenToThirtyError" error={this.state.eighteenToThirtyError} helperText={this.state.eighteenToThirtyMessage} onBlur={this.checkValidityNumberFields} type="number" name="eighteenToThirty" label="Adultos 18-30 anos" required inputProps={{style: {fontSize: 17}}} InputLabelProps={{style: {fontSize: 17, position:"absolute" , top:"-20%"} }} onChange={this.handleChange} fullWidth inputProps={{ min: 0, max: 99 }} />
                            </div>  </Col>
                        <Col xl="2" lg="3" md="12"><div className="marginTop">
                            <TextField value={this.state.thirtyOneToSixtyFive} errorvar="thirtyOneToSixtyFiveError" error={this.state.thirtyOneToSixtyFiveError} helperText={this.state.thirtyOneToSixtyFiveMessage} onBlur={this.checkValidityNumberFields} type="number" name="thirtyOneToSixtyFive" label="Adultos 31-65 anos" inputProps={{style: {fontSize: 17}}} InputLabelProps={{style: {fontSize: 17, position:"absolute" , top:"-20%"} }} required onChange={this.handleChange} fullWidth inputProps={{ min: 0, max: 99 }} />
                            </div>  </Col>
                        <Col xl="2" lg="3" md="12"><div className="marginTop">
                            <TextField value={this.state.overSixtyFive} errorvar="overSixtyFiveError" error={this.state.overSixtyFiveError} helperText={this.state.overSixtyFiveMessage} onBlur={this.checkValidityNumberFields} type="number" name="overSixtyFive" label="Adultos com mais de 65 anos" inputProps={{style: {fontSize: 17}}} InputLabelProps={{style: {fontSize: 17, position:"absolute" , top:"-20%"} }} required onChange={this.handleChange} fullWidth inputProps={{ min: 0, max: 99 }} />
                            </div>
                            </Col>

                        {(!this.state.validAgregate)
                                ? <p style={{ color: 'red', marginLeft: '15px' }}>A soma dos elementos por idade deve ser igual ao valor indicado na composição do agregado familiar</p>
                            : <></>
                        }

                    </Row>

                    <Row className="rowSpace experienceTooltip">
                        <Col xl="3" lg="3" md="12">
                            <FormControl fullWidth required>
                                <InputLabel id="genre-label">Grau de instrução</InputLabel>
                                <Select native labelId="genre-label" name="degree" value={this.state.degree} onChange={this.handleChange} displayEmpty required inputProps={{ max: 2 }} >
                                    <option aria-label="None" value="" />
                                    {
                                        this.state.masterData.map(function (item, i) {

                                            if (item.key === "GRAUINSTRUCAO")
                                                return <option value={item.value}>{item.value}</option>
                                        })}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xl="3" lg="3" md="12">
                            <FormControl fullWidth required>
                                <InputLabel id="genre-label">Situação profissional</InputLabel>
                                <Select native labelId="genre-label" name="job" value={this.state.job} onChange={this.handleChange} displayEmpty required inputProps={{ max: 2 }} >
                                    <option aria-label="None" value="" />

                                    {
                                        this.state.masterData.map(function (item, i) {

                                            if (item.key === "SITUACAOPROFISSIONAL")
                                                return <option value={item.value}>{item.value}</option>
                                        })}
                                </Select>
                            </FormControl>
                        </Col>
                       
                    </Row>
                    

                    <Row className="rowSpace experienceTooltip spanMargin">
                        <span>Motivo principal do pedido de apoio alimentar do agregado familiar:</span>
                    </Row>

                    <Row className="rowSpace experienceTooltip">
                        {
                            <Col lg="6" md="12">
                                <FormControl fullWidth required>
                                    <InputLabel id="genre-label">Motivo do pedido de apoio alimentar</InputLabel>
                                    <Select native labelId="genre-label" name="motivo" value={this.state.motivo} onChange={this.handleChange} displayEmpty required inputProps={{ max: 2 }} >
                                        <option aria-label="None" value="" />
                                        {
                                            this.state.masterData.map(function (item, i) {

                                                if (item.key === "RAZAOAPOIO")
                                                    return <option value={item.value}>{item.value}</option>
                                            })}
                                    </Select>
                                </FormControl>
                            </Col>
                        }
                    </Row>

                    <Row className="rowSpace experienceTooltip">
                        <Col xl="4" lg="5" md="12">
                                <FormControlLabel control={<Checkbox checked={this.state.support} required={false} onChange={this.handleChangeCheckbox} name="support" color="primary" />} label={<div className="blackColor">Já sou apoiado por uma instituição</div>} />
                        </Col>
                        {this.state.support &&
                            <Col lg="5" md="12">
                                <TextField value={this.state.supportLocation} onBlur={this.checkValidity} name="supportLocation" label="Nome da instituição" required onChange={this.handleChange} fullWidth inputProps={{ min: 0, max: 99 }} />
                            </Col>
                        }
                    </Row>

                    <Row className="rowSpace experienceTooltip">
                            <Col xl="12" lg="12" md="12">
                                <TextField value={this.state.sugestao} onBlur={this.checkStrings} error={this.state.sugestaoError} name="sugestao" label="Outras Informações"
                                    helperText={this.state.sugestaoMessage} onChange={this.handleChange} fullWidth inputProps={{ minLength: 0, maxLength: 500 }} />

                                {(this.state.sugestao.length >= 500)
                                    ? <p style={{
                                        color: 'red', fontSize: '0.75rem' }}>Limite de 500 caracteres alcançado</p>
                                    : <></>
                                }

                            </Col>

                    </Row>

                    <CustomizedDialogs first={t("error.first")} second={t("error.second")} close={t("error.close")} onClose={this.closeDialog.bind(this)} onOpen={this.openDialog.bind(this)} openState={this.state.open}></CustomizedDialogs>
                    <ReCaptcha sitekey="6LfDqSIbAAAAAJlBlcDYPGOUwbocyUVRO-hVnGI7" action='submit' verifyCallback={this.verifyCallback} required ref={ref => this.recaptcha = ref} />

                    <Row className="rowSpace experienceTooltip">
                        <p>
                            Mediante o preenchimento e envio do presente formulário está a fornecer informações que permitem um contacto pessoal.
                            A ENTRAJUDA disponibiliza as informações respeitantes ao tratamento dos seus dados pessoais na política de privacidade
                            disponível <a href="https://www.entrajuda.pt/pages/privacidade" target="_blank"> aqui</a>. Leia atentamente antes de proceder ao envio dos dados.
                        </p>
                    </Row>



                    <Row className="mt-2 experienceTooltip">
                        <Button type="submit" className="ml-auto" variant="contained" color="primary" endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} size="2x" />}>Submeter</Button>
                    </Row>
                </form>

                <div class="footer-container">
                    <b class="footer-title">Apoio</b>
                    <Row className="footer-image justify-content-center">

                    </Row>
                </div>
               

            </Container >}</div>
        );
    }
}



const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
export function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <button id="buttonSubmitclick" hidden variant="outlined" color="primary" onClick={handleClickOpen}>

            </button>
            <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.openState} maxWidth={"md"}>
                <DialogContent dividers>
                    <Container className="successContainer">
                        <Row className="justify-content-center">
                            <h1>Aconteceu um erro!</h1>
                        </Row>
                        <Row className="justify-content-center sucessLines">
                            <FontAwesomeIcon icon={faExclamationCircle} size="10x" className="iconColor" />
                        </Row>
                        <Row className="justify-content-center sucessLines">
                            <span>Por favor tente de novo.</span>

                        </Row>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.onClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
