import React, { Component } from 'react';
import './Curricular.css';
import TextField from '@material-ui/core/TextField';
import { Container, Row, Col } from 'reactstrap';
import 'date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { ReCaptcha } from 'react-recaptcha-v3'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from "react-loader-spinner";

const ResponseApoioConcedidoEnum = {
    SIM : "Sim",
    NAO : "Não",
    ENCAMINHADO : "OutraInstituicao"
}

const ResponseFeedBackStatusEnum = {
    FECHADO :  "Fechado",
    ENCAMINHADO : "Encaminhado para outra instituição tratar",
    RECEBIDO_TRATAR : "Recebido feedback tratar"
}

export class Curricular extends Component {
    static displayName = "Feedback sobre pedido de apoio";

    constructor(props) {
        super(props);
        this.state = {
            completeName: (''),
            spinner: true,
            validId: false,
            open: false,
            masterData: [],
            id: this.props.match.params.id,
            pedidoEntregue: undefined, pedidoEntregueError: false,
            motivoNaoEntrega: (''),
            intershipEndDate: null,
            intershipStartDate: null,
            periocidade: (''),
            motivo: (''),
            segundaLinha: (''),
            tipoApoio: (''),
            captcha: false,
            privacy: false,
            outro: (''),
            outroDeixarReceber: (''),
            language: (''),
            encaminhadoParaInstituicao: (''),
            encaminhadoParaPessoaContacto: (''),
            encaminhadoParaEmail: (''),
            freguesiaCorreta: (''),
            observacoesFeedback: (''),
            pedidoFeedbackRespondidoPor: ('')
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleIntershipStartDate = this.handleIntershipStartDate.bind(this);
        this.handleIntershipEndDate = this.handleIntershipEndDate.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addEmptyValue = this.addEmptyValue.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.handleChangePedido = this.handleChangePedido.bind(this);
        this.handleOutroMotivo = this.handleOutroMotivo.bind(this);
        this.handleOutroMotivoDeixarReceber = this.handleOutroMotivoDeixarReceber.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.customStatusValueFromMotivoNaoEntrega = this.customStatusValueFromMotivoNaoEntrega.bind(this);
        this.validateStatus = this.validateStatus.bind(this);

    }
    
    componentDidMount() {

        if (this.props.match.params.id === "") { this.props.history.push('/fail') }

        fetch('externalinfo/getAidRequestbyid?id=' + this.props.match.params.id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((result) => {
            if (result.status === 200) {
                result.json().then((data) => {
                    if (data.filter((c) => c.key === 'Active')[0].value === 'False') {
                        this.props.history.push('/fail')
                    } else {
                       this.setState({ completeName: data.filter((c) => c.key === 'Name')[0].value, validId: true });
                    }

                });
            } else {
                this.props.history.push('/fail')
                console.log(result)
            }
        });
        fetch('externalinfo/getmasterdata', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((result) => {
            if (result.status === 200) {
                result.json().then((data) => {
                    this.setState({ masterData: data, spinner: false })
                });
            } else {
                console.log(result)
            }
        });
    }

    handleChangeCheckbox(event) {
        if (event.target) {
            const name = event.target.name;
            this.setState({ [name]: event.target.checked });
        }
    }
    handleOutroMotivo(event) {
        this.setState({ outro: "" });
        this.handleChange(event);
    }
    handleOutroMotivoDeixarReceber(event) {
        this.setState({ outroDeixarReceber: "" });
        this.handleChange(event);
    }
    handleChangePedido(event) {
        if (event.target) {
            this.setState({
                motivoNaoEntrega: (''),
                intershipEndDate: null,
                intershipStartDate: null,
                periocidade: (''),
                motivo: (''),
                segundaLinha: (''),
                tipoApoio: ('')
            });
            this.handleChange(event);
        }
    }
    handleIntershipStartDate(event) {
        this.setState({ intershipStartDate: event });
    }
    handleIntershipEndDate(event) {
        this.setState({ intershipEndDate: event });
    }
    handleChange(event) {
        if (event.target) {
            const name = event.target.name;
            this.setState({ [name]: event.target.value });
        }
    }
    validateStatus() {

        switch (this.state.pedidoEntregue) {
            case ResponseApoioConcedidoEnum.SIM:
                return ResponseFeedBackStatusEnum.FECHADO;
            case ResponseApoioConcedidoEnum.NAO:
                return this.customStatusValueFromMotivoNaoEntrega(this.state.motivoNaoEntrega);
            case ResponseApoioConcedidoEnum.ENCAMINHADO:
                return ResponseFeedBackStatusEnum.ENCAMINHADO;
            default:
                return "ERRO";
        }
    }

    customStatusValueFromMotivoNaoEntrega(item) {

        switch (item) {

            case "Não reúne os requisitos para apoio":
                return ResponseFeedBackStatusEnum.FECHADO;
            case "Já apoiado":
                return ResponseFeedBackStatusEnum.FECHADO;
            case "Já não necessita de apoio":
                return ResponseFeedBackStatusEnum.FECHADO;
            case "A morada do pedido não corresponde à nossa zona de atuação (indique a freguesia correta)":
                return ResponseFeedBackStatusEnum.RECEBIDO_TRATAR;
            case "Processo em análise":
                return ResponseFeedBackStatusEnum.RECEBIDO_TRATAR;
            case "Não é possível contactar a pessoa":
                return ResponseFeedBackStatusEnum.FECHADO;
            case "A Instituição não tem capacidade para apoiar mais pessoas (Alimentos/RH/Distribuição)​":
                return ResponseFeedBackStatusEnum.RECEBIDO_TRATAR;
            case "Outros (por favor especificar)":
                return ResponseFeedBackStatusEnum.RECEBIDO_TRATAR;

            default:
                return ResponseFeedBackStatusEnum.RECEBIDO_TRATAR;
        }
    }

    handleSubmit(event) {
        if (this.state.pedidoEntregue === undefined) {
            this.setState({ pedidoEntregueError: true });
        } else {
            const obj = {
                id: this.state.id,
                pedidoEntregue: this.state.pedidoEntregue,
                periocidade: this.state.periocidade,
                tipoApoio: this.state.tipoApoio,
                segundaLinha: this.state.segundaLinha,
                motivoNaoEntrega: this.state.motivoNaoEntrega,
                freguesiaCorreta: this.state.freguesiaCorreta,
                encaminhadoParaInstituicao: this.state.encaminhadoParaInstituicao,
                encaminhadoParaPessoaContacto: this.state.encaminhadoParaPessoaContacto,
                encaminhadoParaEmail: this.state.encaminhadoParaEmail,
                statusPedido: this.validateStatus(),
                observacoesFeedback: this.state.outro.length === 0 ? this.state.observacoesFeedback : `${this.state.outro}-${this.state.observacoesFeedback}`,
                pedidoFeedbackRespondidoPor: this.state.pedidoFeedbackRespondidoPor
            }

            fetch('aid/createfeedbackrequest', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj)
            }).then((result) => {
                if (result.status === 200) {
                    this.props.history.push('/success')
                } else {
                    console.log(result)


                    this.setState({ open: true });
                }
            }
            )
            event.preventDefault();
        }
        event.preventDefault();
    }

    verifyCallback = (recaptchaToken) => {
        if (recaptchaToken)
            this.setState({ captcha: true });
    }

    closeDialog(event) {
        this.setState({ open: false })
    }

    openDialog(event) {
        this.setState({ open: true })
    }

    addEmptyValue(listName) {
        let saveState = this.state[listName];

        let newListItem;

        switch (listName) {
            case "educationList":
                newListItem = { academicDegree: (''), course: (''), institution: (''), average: (''), dateOfConclusion: null };
                break;
            default:
                newListItem = []
        }

        saveState.push(newListItem)

        this.setState({ [listName]: saveState });
    }

    updateValue(item, label, index, listName) {
        let saveState = this.state[listName];
        saveState[index][label] = item;

        this.setState({ [listName]: saveState });
    }

    removeValue(index, listName) {
        let saveState = this.state[listName];
        saveState.splice(index, 1);
        this.setState({ [listName]: saveState });
    }

    render() {
        const { t, i18n } = this.props;

        this.state.language = i18n.language;

        return (
            <div className="containerParent">
                {(this.state.spinner)
                    ?
                    <div className="child">
                        <Loader
                            type="ThreeDots"
                            color="#000000"
                            height={200}
                            width={200}
                        />
                    </div>
                    :
                    <Container>
                        <Row className="introImage justify-content-center">

                        </Row>
                        <Row className="rowSpace experienceTooltip">
                            <div>
                                <p className="experienceTooltip">Exmos. Senhores</p>
                                <p>A Rede de Emergência Alimentar tem encaminhado para a vossa Instituição pedidos de apoio relativos a famílias que residem na vossa zona de atuação.
                                    Desde já agradecemos o bom acolhimento e a resposta que é prestada a cada caso com o amor que a cada situação exige.</p>
                                <p>Para ser possível o acompanhamento pela Rede de Emergência Alimentar da resposta prestada por cada instituição aos casos encaminhados, muito agradecemos informação sobre os apoios concedidos.</p>
                                <p>Pedíamos, assim, o favor de nos indicarem, através das opções abaixo, o apoio prestado a cada família.</p>
                            </div>
                        </Row>
                        <form onSubmit={this.handleSubmit}>
                            <Row className="experienceTooltip">
                                <h4 className="sectionHeader">Rede de Emergência Alimentar - Informação sobre pedido de apoio</h4>
                            </Row>

                            <Row className="experienceTooltip">
                                <h5>Nome: {this.state.completeName}</h5>
                                <Loader style={{ marginLeft: '10px' }} type="ThreeDots" color="#000" height={25} width={25} visible={!this.state.validId} />
                            </Row>

                            <Row className="rowSpace experienceTooltip">
                                <Col xl="3" lg="3" md="12">
                                    <FormControl required helperText={"Preencimento obrigatório!"} component="fieldset">
                                        <FormLabel required error={this.state.pedidoEntregueError} component="legend">Apoio concedido?</FormLabel>
                                        <RadioGroup aria-label="gender" name="pedidoEntregue" required value={this.state.pedidoEntregue} onChange={this.handleChangePedido}>
                                            <FormControlLabel value={ResponseApoioConcedidoEnum.SIM} control={<Radio />} label={<div className="blackColor">Sim</div>} />
                                            <FormControlLabel value={ResponseApoioConcedidoEnum.NAO} control={<Radio />} label={<div className="blackColor">Não</div>} />
                                            <FormControlLabel value={ResponseApoioConcedidoEnum.ENCAMINHADO} control={<Radio />} label={<div className="blackColor">Encaminhado para outra instituição</div>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Col>

                                {this.state.pedidoEntregue === ResponseApoioConcedidoEnum.SIM &&
                                    <Col xl="4" lg="6" md="12">
                                        <div>
                                            <FormControl fullWidth required>
                                                <InputLabel id="genre-label">Periodicidade do apoio</InputLabel>
                                                <Select native labelId="genre-label" name="periocidade" value={this.state.periocidade} onChange={this.handleChange} displayEmpty required inputProps={{ max: 2 }} >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        this.state.masterData.map(function (item, i) {

                                                            if (item.key == "PERIOCIDADEAPOIO")
                                                                return <option value={item.value}>{item.value}</option>
                                                        })}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth required>
                                                <InputLabel id="genre-label">Tipo de apoio</InputLabel>
                                                <Select native labelId="genre-label" name="tipoApoio" value={this.state.tipoApoio} onChange={this.handleChange} displayEmpty required inputProps={{ max: 2 }} >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        this.state.masterData.map(function (item, i) {

                                                            if (item.key === "TIPOAPOIO")
                                                                return <option value={item.value}>{item.value}</option>
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Col>
                                }

                                {this.state.pedidoEntregue === ResponseApoioConcedidoEnum.ENCAMINHADO &&
                                    <Col xl="4" lg="6" md="12">
                                        <div>
                                            <TextField value={this.state.encaminhadoParaInstituicao} onBlur={this.checkValidity} required name="encaminhadoParaInstituicao" label="Nome da Instituição" onChange={this.handleChange} fullWidth />
                                            <TextField value={this.state.encaminhadoParaPessoaContacto} onBlur={this.checkValidity} name="encaminhadoParaPessoaContacto" label="Pessoa de contacto" onChange={this.handleChange} fullWidth />
                                            <TextField value={this.state.encaminhadoParaEmail} onBlur={this.checkValidity} required name="encaminhadoParaEmail" label="Endereço de e-mail/telefone da nova instituição" onChange={this.handleChange} fullWidth />
                                        </div>
                                    </Col>
                                }

                                {this.state.pedidoEntregue === ResponseApoioConcedidoEnum.NAO &&
                                    <Col xl="7" lg="9" md="12">
                                        <FormControl fullWidth required>
                                            <InputLabel id="genre-label">Motivo da não entrega</InputLabel>
                                            <Select native labelId="genre-label" name="motivoNaoEntrega" value={this.state.motivoNaoEntrega} onChange={this.handleOutroMotivo} displayEmpty required inputProps={{ max: 2 }} >
                                                <option aria-label="None" value="" />
                                                {
                                                    this.state.masterData.map((item) => {
                                                        if (item.key === "MOTIVONAOENTREGA") {
                                                            return <option value={item.value}>{item.value}</option>
                                                        }
                                                    })
                                                }
                                            </Select>
                                        </FormControl>

                                        {this.state.motivoNaoEntrega.includes("A morada do pedido n") &&

                                            <TextField
                                                fullWidth required
                                                value={this.state.freguesiaCorreta}
                                                name="freguesiaCorreta"
                                                label="Indique a nova morada, código postal, concelho e freguesia, se souber"
                                                onChange={this.handleChange}
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        }

                                    </Col>
                                }

                            </Row>

                            <Row>

                                {this.state.motivo.includes("Outros") &&
                                    <Col xl="12" lg="12" md="12">
                                        <TextField value={this.state.outroDeixarReceber} name="outroDeixarReceber" label="Motivo fim do apoio" onChange={this.handleChange} fullWidth required inputProps={{ maxLength: 100 }} />
                                    </Col>
                                }
                                {this.state.motivoNaoEntrega.includes("Outros (por") &&

                                    <Col xl="12" lg="12" md="12">
                                        <TextField value={this.state.outro} name="outro" label="Motivo" onChange={this.handleChange} fullWidth required inputProps={{ maxLength: 100 }} />
                                    </Col>
                                }

                            </Row>

                            <Row>
                                <Col xl="12" lg="12" md="12">
                                    <TextField fullWidth name="observacoesFeedback" label="Observações" onChange={this.handleChange}
                                        value={this.state.observacoesFeedback}
                                        inputProps={{ minLength: 0, maxLength: 500 }}
                                    />

                                    {(this.state.observacoesFeedback.length >= 500)
                                        ? <p style={{
                                            color: 'red', fontSize: '0.75rem'
                                        }}>Limite de 500 caracteres alcançado</p>
                                        : <></>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col xl="12" lg="12" md="12">
                                    <TextField required fullWidth
                                        value={this.state.pedidoFeedbackRespondidoPor}
                                        name="pedidoFeedbackRespondidoPor"
                                        label="O seu nome"
                                        onChange={this.handleChange}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Col>
                            </Row>

                            <CustomizedDialogs first={t("error.first")} second={t("error.second")} close={t("error.close")} onClose={this.closeDialog.bind(this)} onOpen={this.openDialog.bind(this)} openState={this.state.open}></CustomizedDialogs>

                            <ReCaptcha sitekey="6LfDqSIbAAAAAJlBlcDYPGOUwbocyUVRO-hVnGI7" action='submit' verifyCallback={this.verifyCallback} required />

                            <Row className="rowSpace experienceTooltip">
                                <p>
                                    Conforme é do vosso conhecimento, a Rede de Emergência Alimentar garante a total confidencialidade dos dados recebidos e a não transmissão dos mesmos a terceiros, nos termos do RGPD.
                                </p>
                            </Row>

                            <Row className="mt-2">
                                <Button disabled={(this.state.spinner || !this.state.validId)} type="submit" className="ml-auto" variant="contained" color="primary" endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} size="2x" />}>Submeter</Button>
                            </Row>

                        </form>
                    </Container >
                }
           </div>

        );
    }
}
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
export function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <button id="buttonSubmitclick" hidden variant="outlined" color="primary" onClick={handleClickOpen}>

            </button>
            <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.openState} maxWidth={"md"}>
                <DialogContent dividers>
                    <Container className="successContainer">
                        <Row className="justify-content-center">
                            <h1>Erro a submeter o pedido!</h1>
                        </Row>
                        <Row className="justify-content-center sucessLines">
                            <FontAwesomeIcon icon={faExclamationCircle} size="10x" className="iconColor" />
                        </Row>
                        <Row className="justify-content-center sucessLines">
                            <span>Por favor tente de novo.</span>

                        </Row>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.onClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
