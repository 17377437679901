import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import './Home.css';
import { Link } from "react-router-dom";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    const { t } = this.props;

    return (
      <div>
        <Container>
          <Row className="introImage justify-content-center">
            <h1 className="textHeader">{t("home.header")}</h1>
          </Row>
          <Row className="intro introWelcome">
            <h2 className="headerWelcome">{t("home.welcome.header")}</h2>
            <p className="textWelcome acceptN">{t("home.welcome.text")}</p>
          </Row>
          <Row className="intro introApplications">
            <h2 className="headerApplications">{t("home.spontaneous.header")}</h2>
            <p className="border-bottom acceptN">{t("home.spontaneous.text")}</p>
            <Link className="linkColor" to="/spontaneous"><p className="headerApplications">{t("home.send")}</p></Link>
          </Row>
          <Row className="intro introApplications">
            <h2 className="headerApplications">{t("home.curricular.header")}</h2>
            <p className="border-bottom acceptN">{t("home.curricular.text")}</p>
            <Link className="linkColor" to="/curricular"><p className="headerApplications">{t("home.send")}</p></Link>
          </Row>
        </Container>
      </div >
    );
  }
}
